import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/BrokerPage/Banner/Banner";
import WhyBecome from "../../components/BrokerPage/WhyBecome/WhyBecome";

const BrokerPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>WEALTH ADVISORY · Introducing Broker</title>
        <meta name="title" content="Become an Introducing Broker with WEALTH ADVISORY - Unlock Lucrative Opportunities" />
        <meta property="og:title" content="Become an Introducing Broker with WEALTH ADVISORY - Unlock Lucrative Opportunities" />
        <meta property="twitter:title" content="Become an Introducing Broker with WEALTH ADVISORY - Unlock Lucrative Opportunities" />

        <meta name="description" content="Join WEALTH ADVISORY as an Introducing Broker and access a world of lucrative opportunities. Earn commissions while introducing others to the benefits of our advanced trading platform." />
        <meta property="og:description" content="Join WEALTH ADVISORY as an Introducing Broker and access a world of lucrative opportunities. Earn commissions while introducing others to the benefits of our advanced trading platform." />
        <meta property="twitter:description" content="Join WEALTH ADVISORY as an Introducing Broker and access a world of lucrative opportunities. Earn commissions while introducing others to the benefits of our advanced trading platform." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <WhyBecome />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default BrokerPage;
