import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <Fade left>
                    <h1 className="font-50-28">
                        <Trans>
                            {t('about.banner.title')}
                        </Trans>
                    </h1>
                </Fade>
                <div className={`${classes.text} font-17`}>
                    <div className={`${classes.col} txt-container`}>
                        <p>
                            {t('about.banner.text_01')}
                        </p>
                        <p>
                            {t('about.banner.text_02')}
                        </p>
                    </div>
                    <div className={`${classes.col} txt-container`}>
                        <p>
                            {t('about.banner.text_03')}
                        </p>
                        <p>
                            {t('about.banner.text_04')}
                        </p>
                        <p>
                            {t('about.banner.text_05')}
                        </p>
                    </div>
                </div>
                <div className={`${classes.btn} mt-btn`}>
                    <Button />
                </div>
            </div>
        </section>
    );
};

export default Banner;
