import logo from '../assets/img/logo.svg';
import img1 from '../assets/img/HomePage/Banner/bg.webp';
import img2 from '../assets/img/HomePage/Banner/chart.avif';
import img3 from '../assets/img/HomePage/Banner/light.webp';
import img4 from '../assets/img/AccountsPage/banner.avif';
import img5 from '../assets/img/AssetsPage/banner.webp';
import img6 from '../assets/img/BrokerPage/banner.avif';
import img7 from '../assets/img/FundPage/banner.avif';
import img8 from '../assets/img/ReferralPage/banner.avif';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
];
