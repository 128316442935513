import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/HomePage/Banner/Banner";
import UnderBanner from "../components/HomePage/UnderBanner/UnderBanner";
import Steps from "../components/HomePage/Steps/Steps";
import Explore from "../components/HomePage/Explore/Explore";
import WeAre from "../components/HomePage/WeAre/WeAre";
import WhyTrade from "../components/HomePage/WhyTrade/WhyTrade";
import TradeToday from "../components/HomePage/TradeToday/TradeToday";
import FastOpening from "../components/HomePage/FastOpening/FastOpening";
import OurRewards from "../components/HomePage/OurRewards/OurRewards";
import FastMethods from "../components/HomePage/FastMethods/FastMethods";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>WEALTH ADVISORY</title>
        <meta name="title" content="Welcome to WEALTH ADVISORY - Your Gateway to Smart Trading" />
        <meta property="og:title" content="Welcome to WEALTH ADVISORY - Your Gateway to Smart Trading" />
        <meta property="twitter:title" content="Welcome to WEALTH ADVISORY - Your Gateway to Smart Trading" />

        <meta name="description" content="Explore WEALTH ADVISORY, where innovation meets precision in online trading. Discover a world of financial opportunities with our advanced platform. Start trading today!" />
        <meta property="og:description" content="Explore WEALTH ADVISORY, where innovation meets precision in online trading. Discover a world of financial opportunities with our advanced platform. Start trading today!" />
        <meta property="twitter:description" content="Explore WEALTH ADVISORY, where innovation meets precision in online trading. Discover a world of financial opportunities with our advanced platform. Start trading today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <Steps />
        <Explore />
        <WeAre />
        <WhyTrade />
        <TradeToday />
        <FastOpening />
        <FastMethods />
        <OurRewards />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
