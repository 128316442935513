import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AboutPage/Banner/Banner";
import Broker from "../components/AboutPage/Broker/Broker";
import WhyInvestors from "../components/AboutPage/WhyInvestors/WhyInvestors";
import Contacts from "../components/AboutPage/Contacts/Contacts";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>WEALTH ADVISORY · About Us</title>
        <meta name="title" content="About WEALTH ADVISORY - Empowering Your Financial Success" />
        <meta property="og:title" content="About WEALTH ADVISORY - Empowering Your Financial Success" />
        <meta property="twitter:title" content="About WEALTH ADVISORY - Empowering Your Financial Success" />

        <meta name="description" content="Learn about WEALTH ADVISORY's mission, vision, and commitment to empowering your financial success. Join us in reshaping the future of online trading." />
        <meta property="og:description" content="Learn about WEALTH ADVISORY's mission, vision, and commitment to empowering your financial success. Join us in reshaping the future of online trading." />
        <meta property="twitter:description" content="Learn about WEALTH ADVISORY's mission, vision, and commitment to empowering your financial success. Join us in reshaping the future of online trading." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <WhyInvestors />
        <Broker />
        <Contacts />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
