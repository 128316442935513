import React, { useEffect, useState } from "react";
import classes from './WeAre.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/we_are.webp';
import { Fade } from "react-reveal";

const WeAre = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={classes.head}>
                        <Fade left>
                            <div className={`${classes.title} font-100`}>
                                {t('home.we_are.title')}
                            </div>
                        </Fade>
                        <Fade left>
                            <div className={`${classes.subtitle} font-50`}>
                                {t('home.we_are.subtitle')}
                            </div>
                        </Fade>
                    </h2>
                    <p className={`${classes.text_01} font-30`}>
                        {t('home.we_are.text_01')}
                    </p>
                    <p className={`${classes.text_02} font-17`}>
                        {t('home.we_are.text_02')}
                    </p>
                </div>
                {windowWidth > 992 &&
                    <div className={classes.image}>
                        <img className={classes.img} src={image} alt='' />
                    </div>
                }
            </div>
        </section>
    );
};

export default WeAre;
