import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/ReferralPage/banner.avif';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className="container">
                <div className={classes.wrap}>
                    <Fade left>
                        <h1 className={`${classes.title} font-70`}>
                            {t('referral.banner.title')}
                        </h1>
                    </Fade>
                    <Fade right>
                        <h2 className={`${classes.subtitle} font-50-28`}>
                            {t('referral.banner.subtitle')}
                        </h2>
                    </Fade>
                    <p className={`${classes.text} font-17`}>
                        {t('referral.banner.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
            </div>
            <Fade right>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </Fade>
        </section>
    );
};

export default Banner;
