import React from "react";
import classes from './Faqs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const tabs = [
    {
        title: 'referral.faqs.tab_01.title',
        text: 'referral.faqs.tab_01.text',
        list: [
            'referral.faqs.tab_01.list.li_01',
            'referral.faqs.tab_01.list.li_02',
            'referral.faqs.tab_01.list.li_03',
        ],
    },
    {
        title: 'referral.faqs.tab_02.title',
        text: 'referral.faqs.tab_02.text',
    },
    {
        title: 'referral.faqs.tab_03.title',
        text: 'referral.faqs.tab_03.text',
    },
];

const Faqs = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-50-28`}>
                    {t('referral.faqs.title')}
                </h2>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <Fade key={index} bottom>
                            <div className={classes.tab}>
                                <div className={classes.tabHead}>
                                    <p className={`${classes.tabNum} font-50`}>
                                        0{index + 1}
                                    </p>
                                    <h4 className={`${classes.tabTitle} font-22`}>
                                        {t(tab.title)}
                                    </h4>
                                </div>
                                <div className={`${classes.tabContent} font-17`}>
                                    <p className={classes.text}>
                                        <Trans>
                                            {t(tab.text)}
                                        </Trans>
                                    </p>
                                    {tab.list &&
                                        <ul className={classes.list}>
                                            {tab.list.map((li, index) =>
                                                <li key={index}>
                                                    {t(li)}
                                                </li>
                                            )}
                                        </ul>
                                    }
                                </div>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faqs;
