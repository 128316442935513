import React from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        title: 'home.underbanner.card_01.title',
        text: 'home.underbanner.card_01.text',
    },
    {
        title: 'home.underbanner.card_02.title',
        text: 'home.underbanner.card_02.text',
    },
    {
        title: 'home.underbanner.card_03.title',
        text: 'home.underbanner.card_03.text',
    },
    {
        title: 'home.underbanner.card_04.title',
        text: 'home.underbanner.card_04.text',
    },
];

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className="container">
                <div className={classes.wrap}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <h4 className="font-30">
                                {t(card.title)}
                            </h4>
                            <p className="font-17">
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;