import React from "react";
import classes from './ClientSupport.module.scss';
import { useTranslation } from "react-i18next";

const ClientSupport = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <p className={classes.title}>
                {t('assets.client_support.title')}
            </p>
            <h2 className={classes.subtitle_01}>
                {t('assets.client_support.subtitle_01')}
            </h2>
            <h4 className={classes.subtitle_02}>
                {t('assets.client_support.subtitle_02')}
            </h4>
            <p className={classes.text}>
                {t('assets.client_support.text')}
            </p>
        </section>
    );
};

export default ClientSupport;
