import React, { useEffect, useState } from "react";
import classes from './Explore.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/HomePage/explore.avif';
import { Fade } from "react-reveal";

const list = [
    'home.explore.li_01',
    'home.explore.li_02',
    'home.explore.li_03',
    'home.explore.li_04',
    'home.explore.li_05',
];

const Explore = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={classes.title}>
                        <Fade left>
                            <div className="font-100">
                                {t('home.explore.title')}
                            </div><br />
                        </Fade>
                        <Fade left>
                            <div className={`${classes.subtitle} font-50`}>
                                {t('home.explore.subtitle')}
                            </div>
                        </Fade>
                    </h2>
                    <p className={`${classes.text} font-17`}>
                        {t('home.explore.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button href="trading-assets">
                            {t('btn.see_more')}
                        </Button>
                    </div>
                </div>
                <ul className={`${classes.list} font-30`}>
                    {list.map((li, index) =>
                        <li className={classes.item} key={index}>
                            <div>
                                0{index + 1}
                            </div>
                            <div>
                                {t(li)}
                            </div>
                        </li>
                    )}
                </ul>
                {windowWidth > 1220 &&
                    <Fade right>
                        <div className={classes.image}>
                            <img className={classes.img} src={image} alt='' />
                        </div>
                    </Fade>
                }
                <div className={`${classes.btnMob} mt-btn`}>
                    <Button href="trading-assets">
                        {t('btn.see_more')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default Explore;
