import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/FundPage/banner.avif';
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className="container">
                <div className={classes.wrap}>
                    <div className={classes.content}>
                        <Fade left>
                            <p className="font-70">
                                WEALTH ADVISORY
                            </p>
                        </Fade>
                        <Fade right>
                            <h1 className={`${classes.title} font-50-28`}>
                                {t('fund.banner.title')}
                            </h1>
                        </Fade>
                        <p className={`${classes.text} font-17`}>
                            {t('fund.banner.text')}
                        </p>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    </div>
                    <Fade right>
                        <div className={classes.image}>
                            <img src={image} alt='' />
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
};

export default Banner;
