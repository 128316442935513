import React from "react";
import classes from './Cashback.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/ReferralPage/cashback.avif';
import { Fade } from "react-reveal";

const Cashback = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade left>
                        <p className="font-100">
                            WEALTH ADVISORY
                        </p>
                    </Fade>
                    <Fade left>
                        <h2 className={`${classes.title} font-50`}>
                            {t('referral.cashback.title')}
                        </h2>
                    </Fade>
                    <p className={`${classes.text} font-17`}>
                        {t('referral.cashback.text')}
                    </p>
                </div>
                <Fade right>
                    <div className={classes.image}>
                        <img src={image} alt='' />
                    </div>
                </Fade>
            </div>
        </section>
    );
};

export default Cashback;
