import React from "react";
import classes from './Tab.module.scss';
import { Trans, useTranslation } from "react-i18next";

const Tab = ({ data }) => {
    const { t } = useTranslation();
    const { image, title, text, blocks } = data;
    return (
        <section className={classes.tab}>
            <h4 className={`${classes.title} font-30`}>
                {t(title)}
            </h4>
            <div className={`${classes.text} font-17`}>
                {typeof (text) === 'string' ?
                    <p>
                        {t(text)}
                    </p>
                    :
                    text.map((txt, txtIndex) =>
                        <p key={txtIndex}>
                            {t(txt)}
                        </p>
                    )
                }
            </div>
            <div className={classes.image}>
                <img className={classes.img} src={image} alt='' />
                {data.list &&
                    <ul className={`${classes.list} font-17`}>
                        {data.list.map((li, liIndex) =>
                            <li className={classes.item} key={liIndex}>
                                <svg className={classes.check} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path d="M13.5 0C6.05618 0 0 6.05618 0 13.5C0 20.9438 6.05618 27 13.5 27C20.9438 27 27 20.9438 27 13.5C27 6.05618 20.9438 0 13.5 0ZM11.0337 20.2747L5.8024 14.4612L7.34647 13.0716L10.9811 17.11L19.601 6.84476L21.1937 8.17788L11.0337 20.2747Z" fill="#F9DB71" />
                                </svg>
                                <p>
                                    {t(li)}
                                </p>
                            </li>
                        )}
                    </ul>
                }
            </div>
            <div className={classes.blocks}>
                {blocks.map((block, index) =>
                    <div key={index} className={classes.block}>
                        <h6 className="font-22">
                            {t(block.title)}
                        </h6>
                        <div className="font-17 txt-container">
                            {block.text.map((txt, txtIndex) =>
                                <p key={txtIndex}>
                                    <Trans>
                                        {t(txt)}
                                    </Trans>
                                </p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Tab;
