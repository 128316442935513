import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/FundPage/Banner/Banner";
import GlobalMarkets from "../../components/FundPage/GlobalMarkets/GlobalMarkets";
import WhyBecome from "../../components/FundPage/WhyBecome/WhyBecome";

const FundPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>WEALTH ADVISORY · Fund Managers</title>
        <meta name="title" content="WEALTH ADVISORY Fund Managers - Expertise You Can Trust, Performance You Deserve" />
        <meta property="og:title" content="WEALTH ADVISORY Fund Managers - Expertise You Can Trust, Performance You Deserve" />
        <meta property="twitter:title" content="WEALTH ADVISORY Fund Managers - Expertise You Can Trust, Performance You Deserve" />

        <meta name="description" content="Discover the expertise of our fund managers at WEALTH ADVISORY. Trust your investments to seasoned professionals dedicated to delivering outstanding performance." />
        <meta property="og:description" content="Discover the expertise of our fund managers at WEALTH ADVISORY. Trust your investments to seasoned professionals dedicated to delivering outstanding performance." />
        <meta property="twitter:description" content="Discover the expertise of our fund managers at WEALTH ADVISORY. Trust your investments to seasoned professionals dedicated to delivering outstanding performance." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <GlobalMarkets />
        <WhyBecome />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FundPage;
