import React, { useEffect, useState } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import bg from '../../../assets/img/HomePage/Banner/bg.webp';
import chart from '../../../assets/img/HomePage/Banner/chart.avif';
import light from '../../../assets/img/HomePage/Banner/light.webp';
import item_01 from '../../../assets/img/HomePage/Banner/item_01.webp';
import item_02 from '../../../assets/img/HomePage/Banner/item_02.webp';
import item_03 from '../../../assets/img/HomePage/Banner/item_03.webp';
import item_04 from '../../../assets/img/HomePage/Banner/item_04.webp';
import item_05 from '../../../assets/img/HomePage/Banner/item_05.webp';
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className={classes.container}>
            <div className="container">
                <div className={classes.wrap}>
                    <div className={classes.content}>
                        <h1 className="font-100">
                            {t('home.banner.title')}
                        </h1>
                        <h2 className={`${classes.subtitle} font-30`}>
                            {t('home.banner.subtitle')}
                        </h2>
                        <p className={`${classes.text} font-17`}>
                            {t('home.banner.text')}
                        </p>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button login/>
                        </div>
                    </div>
                    <Fade left>
                        <img className={`${classes.item} ${classes.item_01}`} src={item_01} alt='' />
                    </Fade>
                    {windowWidth > 1220 &&
                        <>
                            <Fade left>
                                <img className={`${classes.item} ${classes.item_02}`} src={item_02} alt='' />
                            </Fade>
                            <Fade left>
                                <img className={`${classes.item} ${classes.item_03}`} src={item_03} alt='' />
                            </Fade>
                            <Fade right>
                                <img className={`${classes.item} ${classes.item_04}`} src={item_04} alt='' />
                            </Fade>
                        </>
                    }
                    <Fade right>
                        <img className={`${classes.item} ${classes.item_05}`} src={item_05} alt='' />
                    </Fade>
                </div>
            </div>
            <img className={classes.chart} src={chart} alt='' />
            <img className={classes.bg} src={bg} alt='' />
            {windowWidth > 800 &&
                <img className={classes.light} src={light} alt='' />
            }
        </section>
    );
};

export default Banner;
