import React from "react";
import classes from './OurRewards.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/OurRewards/image_01.png';
import image_02 from '../../../assets/img/HomePage/OurRewards/image_02.png';
import image_03 from '../../../assets/img/HomePage/OurRewards/image_03.png';
import image_04 from '../../../assets/img/HomePage/OurRewards/image_04.png';
import image_05 from '../../../assets/img/HomePage/OurRewards/image_05.png';
import { Fade } from "react-reveal";

const awards = [
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
];

const OurRewards = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-50`}>
                    <Trans>
                        {t('home.our_rewards.title')}
                    </Trans>
                </h2>
                <div className={classes.awards}>
                    {awards.map((award, index) =>
                    <Fade key={index} bottom>
                        <img className={classes.award} src={award} alt='' />
                    </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OurRewards;
