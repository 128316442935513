import React, { useEffect, useState } from "react";
import classes from './WhyBecome.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        title: 'fund.why_become.tab_01.title',
        text: 'fund.why_become.tab_01.text',
    },
    {
        title: 'fund.why_become.tab_02.title',
        text: 'fund.why_become.tab_02.text',
    },
    {
        title: 'fund.why_become.tab_03.title',
        text: 'fund.why_become.tab_03.text',
    },
    {
        title: 'fund.why_become.tab_04.title',
        text: 'fund.why_become.tab_04.text',
    },
    {
        title: 'fund.why_become.tab_05.title',
        text: 'fund.why_become.tab_05.text',
    },
    {
        title: 'fund.why_become.tab_06.title',
        text: 'fund.why_become.tab_06.text',
    },
];

const WhyBecome = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            {windowWidth > 992 ?
                <div className={classes.wrap}>
                    <div className={classes.buttons}>
                        {tabs.map((tab, index) =>
                            <div
                                key={index}
                                className={`${classes.btn} ${active === index ? classes.active : ''} font-17 no-select`}
                                onClick={() => setActive(index)}
                            >
                                <p>
                                    {t(tab.title)}
                                </p>
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 5L19 12L12 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        )}
                    </div>
                    <div className={classes.content}>
                        <h2 className="font-30">
                            {t('broker.why_become.title')}
                        </h2>
                        <div className={classes.card}>
                            <h4 className="font-22">
                                {t(tabs[active].title)}
                            </h4>
                            <p className={`${classes.text} font-17`}>
                                {t(tabs[active].text)}
                            </p>
                        </div>
                    </div>
                </div>
                :
                <div className={classes.wrapMob}>
                    <h4 className="font-50">
                        {t('broker.why_become.title')}
                    </h4>
                    <div className={classes.tabs}>
                        {tabs.map((tab, index) =>
                            <div
                                key={index}
                                className={`${classes.tab} ${active === index ? classes.active : ''} font-17`}
                            >
                                <div
                                    className={`${classes.tabHeader} no-select`}
                                    onClick={() => setActive(index)}
                                >
                                    <h4 className={classes.tabTitle}>
                                        {t(tab.title)}
                                    </h4>
                                    <div className={classes.tabStatus}></div>
                                </div>
                                <p className={classes.tabText}>
                                    {t(tab.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            }
        </section>
    );
};

export default WhyBecome;
