import React from "react";
import classes from './Contacts.module.scss';
import { useTranslation } from "react-i18next";

const Contacts = () => {
    const { t } = useTranslation();
    const { hostname } = document.location;
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.block}>
                    <p className={`${classes.title} font-30`}>
                        {t('about.contacts.address')}
                    </p>
                    <div className={`${classes.add} font-17`}>
                        <div className={classes.address}>
                            <div className={classes.addressTitle}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="#F9DB71" />
                                </svg>
                                <p>
                                    Australian Office:
                                </p>
                            </div>
                            <p className={classes.addressText}>
                                NARELLAN NSW 2567
                            </p>
                        </div>
                        <div className={classes.address}>
                            <div className={classes.addressTitle}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z" fill="#F9DB71" />
                                </svg>
                                <p>
                                    Switzerland Office
                                </p>
                            </div>
                            <p className={classes.addressText}>
                                Cours de Rive 5, 1204 Genève.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.block}>
                    <p className={`${classes.title} font-30`}>
                        {t('about.contacts.email')}
                    </p>
                    <a href={`mailto:support@${hostname}`} rel="noreferrer" className={classes.item}>
                        support@{hostname}
                    </a>
                </div>
                <div className={classes.block}>
                    <p className={`${classes.title} font-30`}>
                        {t('about.contacts.call')}
                    </p>
                    <a href="tel:+442080976128" rel="noreferrer" className={classes.item}>
                        +442080976128
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Contacts;
