import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AssetsPage/banner.webp';
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className="container">
                <div className={classes.wrap}>
                    <div className={classes.content}>
                        <Fade left>
                            <h1 className={`${classes.title} font-70`}>
                                {t('assets.banner.title')}
                            </h1>
                        </Fade>
                        <h2 className={`${classes.subtitle} font-50`}>
                            {t('assets.banner.subtitle')}
                        </h2>
                        <p className={`${classes.text} font-17`}>
                            {t('assets.banner.text')}
                        </p>
                        <div className={`${classes.btn} mt-btn`}>
                            <Button />
                        </div>
                    </div>
                </div>
            </div>
            <Fade right>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </Fade>
        </section>
    );
};

export default Banner;
