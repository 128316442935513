import React from "react";
import classes from './RefProgram.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const cards = [
    {
        title: '$75',
        text: 'referral.ref_program.card_01.text',
    },
    {
        title: '$150',
        text: 'referral.ref_program.card_02.text',
    },
    {
        title: '$250',
        text: 'referral.ref_program.card_03.text',
    },
];

const RefProgram = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-50-28`}>
                    {t('referral.ref_program.title')}
                </h2>
                <p className={`${classes.text} font-17`}>
                    {t('referral.ref_program.text')}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                    <Fade key={index} bottom>
                        <div className={classes.card}>
                            <p className="font-25">
                                {card.title}
                            </p>
                            <p className="font-17">
                                <Trans>
                                    {t(card.text)}
                                </Trans>
                            </p>
                        </div>
                    </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default RefProgram;
