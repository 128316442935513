import React from "react";
import classes from './FastMethods.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/FastMethods/image_01.png';
import image_02 from '../../../assets/img/HomePage/FastMethods/image_02.png';
import image_03 from '../../../assets/img/HomePage/FastMethods/image_03.png';
import image_04 from '../../../assets/img/HomePage/FastMethods/image_04.png';
import { Fade } from "react-reveal";

const awards = [
    image_01,
    image_02,
    image_03,
    image_04,
];

const FastMethods = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-50`}>
                    {t('home.fast_methods.title')}
                </h2>
                <h4 className={`${classes.subtitle} font-30`}>
                    {t('home.fast_methods.subtitle')}
                </h4>
                <div className={classes.awards}>
                    {awards.map((award, index) =>
                        <Fade key={index} bottom>
                            <img className={classes.award} src={award} alt='' />
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default FastMethods;
