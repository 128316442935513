import React from "react";
import classes from './TierSystem.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const tabs = [
    {
        total: '0 – 500',
        rebate: '$0.50',
    },
    {
        total: '0 – 1000',
        rebate: '$1',
    },
    {
        total: '1000+',
        rebate: '$2',
    },
];

const TierSystem = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-50-28`}>
                    {t('referral.tier_system.title')}
                </h2>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                    <Fade key={index} bottom>
                        <div className={classes.tab}>
                            <div className={`${classes.tier} font-30`}>
                                {t('referral.tier_system.tier')} {index + 1}
                            </div>
                            <div className={classes.content}>
                                <div className={classes.col}>
                                    <p className="font-22">
                                        {t(tab.total)} {t('referral.tier_system.lots')}
                                    </p>
                                    <p className="font-17">
                                        {t('referral.tier_system.total_volume')}
                                    </p>
                                </div>
                                <div className={classes.col}>
                                    <p className="font-22">
                                        {tab.rebate}
                                    </p>
                                    <p className="font-17">
                                        {t('referral.tier_system.rebate')}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.btn}>
                                <Button>
                                    {t('btn.open_account')}
                                </Button>
                            </div>
                        </div>
                    </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TierSystem;
