import _ from 'lodash';
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import { headerRoutes } from "../../../router";
import $ from "jquery";
import logo from "../../../assets/img/logo.svg";
import Button from '../Button/Button';

const languages = [
  { value: "en", label: "EN" },
  { value: "fr", label: "FR" },
  { value: "ru", label: "RU" },
  { value: "pl", label: "PL" },
  { value: "es", label: "ES" },
  { value: "cs", label: "CS" },
];

const Header = () => {
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const getRoute = (route) => {
    if (lang && route) {
      return `/${lang}${route}`;
    }
    return `/${i18n.language}${route}`;
  }

  const setLocale = (newLang) => {
    let newPathname = pathname;
    i18n.changeLanguage(newLang);

    if (lang && newLang) {
      newPathname = pathname.replace(`/${lang}`, `/${newLang}`);
    }

    navigate(newPathname);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale('en');
    }
    // eslint-disable-next-line
  }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
    }
  }

  useEffect(() => {
    listenScroll();
    burgerOpened
      ? $("body").addClass("lock")
      : $("body").removeClass("lock");
  }, [burgerOpened]);

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();
    setBurgerOpened(false);
  }, [pathname]);

  useEffect(() => {
    $("body").hasClass("lock")
      && $("body").removeClass("lock");
  }, []);

  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });
  
  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${classes.hideBg} no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link 
              to={getRoute('/')} 
              onClick={() => setBurgerOpened(false)}
            >
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowLinks}>
            {headerRoutes.map((route, routeIndex) => (
              route.path ?
                <Link
                  key={routeIndex}
                  to={getRoute(route.path)}
                  className={classes.linkItem}
                >
                  {t(route.text)}
                </Link>
                :
                <div key={routeIndex} className={classes.listWrap}>
                  <div className={classes.linkItem}>
                    {t(route.text)}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div className={classes.subRoutes}>
                    {route.routes.map((subRoute, subRouteIndex) =>
                      <Link
                        key={subRouteIndex}
                        className={`${classes.subRoute} ${classes.linkItem}`}
                        to={getRoute(subRoute.path)}
                      >
                        {t(subRoute.text)}
                      </Link>
                    )}
                  </div>
                </div>
            ))}
          </div>
          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
            <a
              href={`${process.env.REACT_APP_CABINET_URL}/signup`}
              className={classes.login}
            >
              {t('btn.sign_up')}
            </a>
            <div
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${burgerOpened && classes.burgerActive}`}
            ></div>
          </div>
        </div>
      </div>
      <div className={`${classes.mobileMenu} ${burgerOpened && classes.mobileMenuActive}`}>
        <div className="container">
          {headerRoutes.map((route, routeIndex) => (
            route.path ?
              <Link
                key={routeIndex}
                to={getRoute(route.path)}
                className={`${classes.aLink}`}
                onClick={() => setBurgerOpened(false)}
              >
                {t(route.text)}
              </Link>
              :
              route.routes.map((subRoute, subRouteIndex) =>
                <Link
                  key={subRouteIndex}
                  to={getRoute(subRoute.path)}
                  className={`${classes.aLink}`}
                  onClick={() => setBurgerOpened(false)}
                >
                  {t(subRoute.text)}
                </Link>
              )
          ))}
          <div className={classes.btn}>
            <Button white>
              {t('btn.sign_up')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;