import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/ReferralPage/Banner/Banner";
import RefProgram from "../../components/ReferralPage/RefProgram/RefProgram";
import Faqs from "../../components/ReferralPage/Faqs/Faqs";
import Cashback from "../../components/ReferralPage/Cashback/Cashback";
import TierSystem from "../../components/ReferralPage/TierSystem/TierSystem";

const ReferralPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>WEALTH ADVISORY · Referral Program</title>
        <meta name="title" content="Exclusive Trading Promotions at WEALTH ADVISORY - Boost Your Profits Today" />
        <meta property="og:title" content="Exclusive Trading Promotions at WEALTH ADVISORY - Boost Your Profits Today" />
        <meta property="twitter:title" content="Exclusive Trading Promotions at WEALTH ADVISORY - Boost Your Profits Today" />

        <meta name="description" content="Take advantage of our special promotions and bonuses at WEALTH ADVISORY. Elevate your trading experience with exclusive offers designed to enhance your profitability" />
        <meta property="og:description" content="Take advantage of our special promotions and bonuses at WEALTH ADVISORY. Elevate your trading experience with exclusive offers designed to enhance your profitability" />
        <meta property="twitter:description" content="Take advantage of our special promotions and bonuses at WEALTH ADVISORY. Elevate your trading experience with exclusive offers designed to enhance your profitability" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <RefProgram />
        <Faqs />
        <Cashback />
        <TierSystem />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ReferralPage;
