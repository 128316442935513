import React from "react";
import classes from './Steps.module.scss';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Fade } from "react-reveal";

const cards = [
    {
        title: 'home.steps.card_01.title',
        text: 'home.steps.card_01.text',
    },
    {
        title: 'home.steps.card_02.title',
        text: 'home.steps.card_02.text',
    },
    {
        title: 'home.steps.card_03.title',
        text: 'home.steps.card_03.text',
    },
    {
        title: 'home.steps.card_04.title',
        text: 'home.steps.card_04.text',
    },
    {
        title: 'home.steps.card_05.title',
        text: 'home.steps.card_05.text',
    },
    {
        title: 'home.steps.card_06.title',
        text: 'home.steps.card_06.text',
    },
];

const Steps = () => {
    const { t } = useTranslation();
    const { lang } = useParams();
    return (
        <section className="mt container">
            <div className={classes.steps}>
                {cards.map((card, index) =>
                    <Fade bottom key={index}>
                        <div className={classes.card}>
                            <div className={`${classes.num} font-50`}>
                                0{index + 1}
                            </div>
                            <div className={classes.content}>
                                <h4 className={`${classes.title} font-30`}>
                                    {t(card.title)}
                                </h4>
                                <p className={classes.text}>
                                    {t(card.text)}
                                </p>
                            </div>
                            <div className={classes.btnWrap}>
                                <a
                                    className={classes.btn}
                                    href={`${process.env.REACT_APP_CABINET_URL}/signup?lang=${lang}`}
                                >
                                    <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5 12L19 12" stroke="#F9DB71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 19L19 12L12 5" stroke="#F9DB71" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </Fade>
                )}
            </div>
        </section>
    );
};

export default Steps;
