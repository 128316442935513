import React from "react";
import classes from './TradeToday.module.scss';
import { useTranslation } from "react-i18next";
import check from '../../../assets/img/list_check.svg';
import { Fade } from "react-reveal";

const list = [
    'home.trade_today.li_01',
    'home.trade_today.li_02',
    'home.trade_today.li_03',
    'home.trade_today.li_04',
    'home.trade_today.li_05',
    'home.trade_today.li_06',
];

const TradeToday = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={classes.head}>
                        <Fade left>
                            <div className={`${classes.title} font-100`}>
                                {t('home.trade_today.title')}
                            </div>
                        </Fade>
                        <div className={`${classes.subtitle} font-30`}>
                            {t('home.trade_today.subtitle')}
                        </div>
                    </h2>
                    <p className={`${classes.text} font-17`}>
                        {t('home.trade_today.text')}
                    </p>
                </div>
                <ul className={`${classes.list} font-17`}>
                    {list.map((li, index) =>
                        <Fade key={index} bottom>
                            <li className={classes.item}>
                                <img className={classes.check} src={check} alt='' />
                                <p className={classes.itemText}>
                                    {t(li)}
                                </p>
                            </li>
                        </Fade>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default TradeToday;
