import HomePage from '../pages/HomePage';
import AssetsPage from '../pages/AssetsPage';
import AccountsPage from '../pages/AccountsPage';
import ReferralPage from '../pages/Promotions/ReferralPage';
import BrokerPage from '../pages/Promotions/BrokerPage';
import FundPage from '../pages/Promotions/FundPage';
import AboutPage from '../pages/AboutPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading-assets', element: AssetsPage },
    { path: '/:lang/account-types', element: AccountsPage },
    { path: '/:lang/promotions/referral-program', element: ReferralPage },
    { path: '/:lang/promotions/introducing-broker', element: BrokerPage },
    { path: '/:lang/promotions/fund-managers', element: FundPage },
    { path: '/:lang/about-us', element: AboutPage },
];


export const headerRoutes = [
    { path: '/trading-assets', text: 'header.assets' },
    { path: '/account-types', text: 'header.accounts' },
    { 
        text: 'header.promotions',
        routes: [
            { path: '/promotions/referral-program', text: 'header.referral' },
            { path: '/promotions/introducing-broker', text: 'header.broker' },
            { path: '/promotions/fund-managers', text: 'header.fund' },
        ],
    },
    { path: '/about-us', text: 'header.about' },
];
