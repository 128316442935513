import React from "react";
import classes from "./Button.module.scss";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Button = ({ children, login, href, dark }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  return (
    <Link
      className={`${classes.button} ${dark ? classes.dark : ''} no-select`}
      to={!href
        ? `${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}?lang=${lang}`
        : `/${lang}/${href}`
      }
    >
      {children
        ? children
        : t('btn.start_now')
      }
    </Link>
  );
};

export default Button;
