import React from "react";
import classes from './Broker.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const Broker = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.col}>
                    <div className={classes.content}>
                        <Fade left>
                            <h2 className="font-50-28">
                                <Trans>
                                    {t('about.broker.title')}
                                </Trans>
                            </h2>
                        </Fade>
                        <p className="font-17">
                            {t('about.broker.text')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.learn_more')}
                        </Button>
                    </div>
                </div>
                <div className={classes.col}>
                    <div className={classes.content}>
                        <Fade right>
                            <h2 className="font-50-28">
                                <Trans>
                                    {t('about.bonus.title')}
                                </Trans>
                            </h2>
                        </Fade>
                        <p className="font-17">
                            {t('about.bonus.text')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.learn_more')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Broker;
