import React, { useState, useEffect } from "react";
import classes from './Accounts.module.scss';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import Button from '../../GlobalComponents/Button/Button';
import true_svg from '../../../assets/img/AccountsPage/Accounts/true.svg';
import false_svg from '../../../assets/img/AccountsPage/Accounts/false.svg';

const buttons = [
    {
        id: 0,
        text: 'Standard',
    },
    {
        id: 1,
        text: 'Professional',
    },
    {
        id: 2,
        text: 'Individual',
    },
];

const standard = [
    {
        title: 'accounts.account_types.account_type',
        rows: ['BRONZE', 'SILVER', 'GOLD', 'PLATINUM', 'PREMIUM'],
    },
    {
        title: 'accounts.account_types.standard.row_00',
        rows: ['$250', '$2.500', '$10.000', '$50.000', '$250.000'],
    },
    {
        title: 'accounts.account_types.standard.row_01',
        rows: [true, true, true, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_02',
        rows: [true, true, true, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_03',
        rows: [true, true, true, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_04',
        rows: [true, true, true, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_05',
        rows: [false, true, true, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_06',
        rows: [false, false, true, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_07',
        rows: [false, false, true, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_08',
        rows: [false, false, true, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_09',
        rows: [false, false, false, true, true],
    },
    {
        title: 'accounts.account_types.standard.row_10',
        rows: [false, false, false, false, true],
    },
];

const professional = [
    {
        title: 'VIP',
        dep: '$500000+',
        text: [
            'accounts.account_types.professional.card_01.text_01',
            'accounts.account_types.professional.card_01.text_02',
        ],
    },
    {
        title: 'VIP +',
        dep: '$1000000+',
        text: [
            'accounts.account_types.professional.card_02.text_01',
            'accounts.account_types.professional.card_02.text_02',
        ],
    },
];

const individual = [
    {
        title: 'Standard Account',
        list: [
            'accounts.account_types.individual.card_01.li_01',
            'accounts.account_types.individual.card_01.li_02',
            'accounts.account_types.individual.card_01.li_03',
            'accounts.account_types.individual.card_01.li_04',
            'accounts.account_types.individual.card_01.li_05',
            'accounts.account_types.individual.card_01.li_06',
            'accounts.account_types.individual.card_01.li_07',
            'accounts.account_types.individual.card_01.li_08',
        ],
    },
    {
        title: 'Staking Account',
        list: [
            'accounts.account_types.individual.card_02.li_01',
            'accounts.account_types.individual.card_02.li_02',
            'accounts.account_types.individual.card_02.li_03',
            'accounts.account_types.individual.card_02.li_04',
            'accounts.account_types.individual.card_02.li_05',
            'accounts.account_types.individual.card_02.li_06',
            'accounts.account_types.individual.card_02.li_07',
            'accounts.account_types.individual.card_02.li_08',
            'accounts.account_types.individual.card_02.li_09',
            'accounts.account_types.individual.card_02.li_10',
            'accounts.account_types.individual.card_02.li_11',
        ],
    },
    {
        title: 'Savings Account',
        list: [
            'accounts.account_types.individual.card_03.li_01',
            'accounts.account_types.individual.card_03.li_02',
            'accounts.account_types.individual.card_03.li_03',
            'accounts.account_types.individual.card_03.li_04',
            'accounts.account_types.individual.card_03.li_05',
            'accounts.account_types.individual.card_03.li_06',
            'accounts.account_types.individual.card_03.li_07',
            'accounts.account_types.individual.card_03.li_08',
        ],
    },
    {
        title: 'High-Yield Account',
        list: [
            'accounts.account_types.individual.card_04.li_01',
            'accounts.account_types.individual.card_04.li_02',
            'accounts.account_types.individual.card_04.li_03',
            'accounts.account_types.individual.card_04.li_04',
            'accounts.account_types.individual.card_04.li_05',
            'accounts.account_types.individual.card_04.li_06',
            'accounts.account_types.individual.card_04.li_07',
            'accounts.account_types.individual.card_04.li_08',
            'accounts.account_types.individual.card_04.li_09',
            'accounts.account_types.individual.card_04.li_10',
            'accounts.account_types.individual.card_04.li_11',
        ],
    },
    {
        title: 'Retirement Account',
        list: [
            'accounts.account_types.individual.card_05.li_01',
            'accounts.account_types.individual.card_05.li_02',
            'accounts.account_types.individual.card_05.li_03',
        ],
    },
];

const Accounts = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [active, setActive] = useState(0);

    const [swiperPro, setSwiperPro] = useState({});
    const [activePro, setActivePro] = useState(0);

    const [swiperInd, setSwiperInd] = useState({});
    const [activeInd, setActiveInd] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.buttons}>
                    {buttons.map((tab) =>
                        <div
                            key={tab.id}
                            className={`${classes.button} ${active === tab.id ? classes.active : ''} font-17 no-select`}
                            onClick={() => setActive(tab.id)}
                        >
                            {t(tab.text)}
                        </div>
                    )}
                </div>
                <div className={classes.block}>
                    {active === 0 &&
                        <div className={classes.standardWrap}>
                            <div className={classes.standard}>
                                {standard.map((row, index) =>
                                    <div className={classes.row} key={index}>
                                        <h4 className={classes.title}>
                                            {t(row.title)}
                                        </h4>
                                        {row.rows.map((col, colIndex) =>
                                            <div className={classes.col} key={colIndex}>
                                                {col === true
                                                    ? <img src={true_svg} alt='' />
                                                    : col === false
                                                        ? <img src={false_svg} alt='' />
                                                        : <>{t(col)}</>
                                                }
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {active === 1 &&
                        <div className={classes.professional}>
                            {windowWidth < 740 &&
                                <div className={classes.nav}>
                                    <div
                                        className={`${classes.navBtn} ${classes.left} ${activePro === 0 ? classes.inactive : ''}`}
                                        onClick={() => swiperPro.slidePrev()}
                                    >
                                        <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div
                                        className={`${classes.navBtn} ${activePro === swiperPro.snapGrid?.length - 1 ? classes.inactive : ''}`}
                                        onClick={() => swiperPro.slideNext()}
                                    >
                                        <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            }
                            <div className={classes.swiperWrap}>
                                <Swiper
                                    modules={[Navigation]}
                                    className="accountseSwiper no-select"
                                    grabCursor
                                    spaceBetween={20}
                                    slidesPerView={1}
                                    onInit={(e) => { setSwiperPro(e) }}
                                    onSlideChange={(e) => setActivePro(e.activeIndex)}
                                    breakpoints={{
                                        992: {
                                            slidesPerView: 2,
                                            spaceBetween: 40,
                                        },
                                        800: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                    }}
                                >
                                    {professional.map((card, index) =>
                                        <SwiperSlide key={index} className="accountsSwiperSlide">
                                            <div className={`${classes.card} font-17`}>
                                                <h4 className={`${classes.cardTitle} font-30`}>
                                                    {t(card.title)}
                                                </h4>
                                                <p className={`${classes.cardDep} font-30`}>
                                                    {t(card.dep)}
                                                </p>
                                                <p className={classes.cardAvl}>
                                                    {t('accounts.account_types.professional.limited_availability')}
                                                </p>
                                                <p className={classes.cardAppl}>
                                                    {t('accounts.account_types.professional.apply_now')}
                                                </p>
                                                <div className={classes.cardText}>
                                                    {card.text.map((txt, txtIndex) =>
                                                        <p key={txtIndex}>
                                                            {t(txt)}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    }
                    {active === 2 &&
                        <div className={classes.individual}>
                            <div className={classes.nav}>
                                <div
                                    className={`${classes.navBtn} ${classes.left} ${activeInd === 0 ? classes.inactive : ''}`}
                                    onClick={() => swiperInd.slidePrev()}
                                >
                                    <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div
                                    className={`${classes.navBtn} ${activeInd === swiperInd.snapGrid?.length - 1 ? classes.inactive : ''}`}
                                    onClick={() => swiperInd.slideNext()}
                                >
                                    <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M5 12H19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 5L19 12L12 19" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className={classes.swiperWrap}>
                                <Swiper
                                    modules={[Navigation]}
                                    className="accountseSwiper no-select"
                                    grabCursor
                                    spaceBetween={20}
                                    slidesPerView={1}
                                    onInit={(e) => { setSwiperInd(e) }}
                                    onSlideChange={(e) => setActiveInd(e.activeIndex)}
                                    breakpoints={{
                                        992: {
                                            slidesPerView: 2,
                                            spaceBetween: 40,
                                        },
                                        800: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                    }}
                                >
                                    {individual.map((card, index) =>
                                        <SwiperSlide key={index} className="accountsSwiperSlide">
                                            <div className={`${classes.card} font-17`}>
                                                <h4 className={`${classes.cardTitle} font-30`}>
                                                    {t(card.title)}
                                                </h4>
                                                <ul className={classes.cardList}>
                                                    {card.list.map((li, liIndex) =>
                                                        <li key={liIndex}>
                                                            {t(li)}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default Accounts;
