import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import ClientSupport from "../ClientSupport/ClientSupport";
import image_01 from '../../../assets/img/AssetsPage/Tabs/image_01.avif';
import image_02 from '../../../assets/img/AssetsPage/Tabs/image_02.avif';
import image_03 from '../../../assets/img/AssetsPage/Tabs/image_03.avif';
import image_04 from '../../../assets/img/AssetsPage/Tabs/image_04.avif';
import image_05 from '../../../assets/img/AssetsPage/Tabs/image_05.avif';
import Tab from "./Tab/Tab";

const tabs = [
    {
        image: image_01,
        title: 'assets.tabs.tab_01.title',
        text: 'assets.tabs.tab_01.text',
        list: [
            'assets.tabs.tab_01.list.li_01',
            'assets.tabs.tab_01.list.li_02',
            'assets.tabs.tab_01.list.li_03',
            'assets.tabs.tab_01.list.li_04',
            'assets.tabs.tab_01.list.li_05',
        ],
        blocks: [
            {
                title: 'assets.tabs.tab_01.block_01.title',
                text: [
                    'assets.tabs.tab_01.block_01.text_01',
                    'assets.tabs.tab_01.block_01.text_02',
                    'assets.tabs.tab_01.block_01.text_03',
                    'assets.tabs.tab_01.block_01.text_04',
                    'assets.tabs.tab_01.block_01.text_05',
                ],
            },
            {
                title: 'assets.tabs.tab_01.block_02.title',
                text: [
                    'assets.tabs.tab_01.block_02.text_01',
                    'assets.tabs.tab_01.block_02.text_02',
                ],
            },
            {
                title: 'assets.tabs.tab_01.block_03.title',
                text: [
                    'assets.tabs.tab_01.block_03.text_01',
                    'assets.tabs.tab_01.block_03.text_02',
                ],
            },
            {
                title: 'assets.tabs.tab_01.block_04.title',
                text: [
                    'assets.tabs.tab_01.block_04.text_01',
                    'assets.tabs.tab_01.block_04.text_02',
                ],
            },
        ],
    },
    {
        image: image_02,
        title: 'assets.tabs.tab_02.title',
        text: 'assets.tabs.tab_02.text',
        blocks: [
            {
                title: 'assets.tabs.tab_02.block_01.title',
                text: [
                    'assets.tabs.tab_02.block_01.text_01',
                    'assets.tabs.tab_02.block_01.text_02',
                ],
            },
            {
                title: 'assets.tabs.tab_02.block_02.title',
                text: [
                    'assets.tabs.tab_02.block_02.text_01',
                ],
            },
        ],
    },
    {
        image: image_03,
        title: 'assets.tabs.tab_03.title',
        text: 'assets.tabs.tab_03.text',
        blocks: [
            {
                title: 'assets.tabs.tab_03.block_01.title',
                text: [
                    'assets.tabs.tab_03.block_01.text_01',
                ],
            },
            {
                title: 'assets.tabs.tab_03.block_02.title',
                text: [
                    'assets.tabs.tab_03.block_02.text_01',
                    'assets.tabs.tab_03.block_02.text_02',
                ],
            },
        ],
    },
    {
        image: image_04,
        title: 'assets.tabs.tab_04.title',
        text: [
            'assets.tabs.tab_04.text_01',
            'assets.tabs.tab_04.text_02',
            'assets.tabs.tab_04.text_03',
        ],
        blocks: [
            {
                title: 'assets.tabs.tab_04.block_01.title',
                text: [
                    'assets.tabs.tab_04.block_01.text_01',
                    'assets.tabs.tab_04.block_01.text_02',
                ],
            },
            {
                title: 'assets.tabs.tab_04.block_02.title',
                text: [
                    'assets.tabs.tab_04.block_02.text_01',
                    'assets.tabs.tab_04.block_02.text_02',
                    'assets.tabs.tab_04.block_02.text_03',
                    'assets.tabs.tab_04.block_02.text_04',
                ],
            },
        ],
    },
    {
        image: image_05,
        title: 'assets.tabs.tab_05.title',
        text: 'assets.tabs.tab_05.text',
        blocks: [
            {
                title: 'assets.tabs.tab_05.block_01.title',
                text: [
                    'assets.tabs.tab_05.block_01.text_01',
                ],
            },
            {
                title: 'assets.tabs.tab_05.block_02.title',
                text: [
                    'assets.tabs.tab_05.block_02.text_01',
                ],
            },
        ],
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.nav}>
                    <div className={classes.buttons}>
                        {tabs.map((tab, index) =>
                            <div
                                key={index}
                                className={`${classes.btn} ${active === index ? classes.active : ''} font-17 no-select`}
                                onClick={() => setActive(index)}
                            >
                                <p>
                                    {t(tab.title)}
                                </p>
                                <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 5L19 12L12 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        )}
                    </div>
                    <ClientSupport />
                </div>
                <div className={classes.tab}>
                    <Tab data={tabs[active]} />
                </div>
            </div>
        </section>
    );
};

export default Tabs;
